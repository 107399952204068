/*!
 * Start Bootstrap - Landing Page (https://startbootstrap.com/template-overviews/landing-page)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-landing-page/blob/master/LICENSE)
 */

body, html {
    width: 100%;
    height: 100%;
}

body, h1, h2, h3, h4, h5, h6 {
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
}

.intro-header {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    color: #333;
    /*background: url(assets/bc-circle-optimized.svg) no-repeat center center;*/
    background-size: 30%;
}

.intro-message {
    position: relative;
    padding-top: 20%;
    padding-bottom: 20%;
}

.intro-message > h1 {
    font-family: "Book Antiqua", Palatino, "Palatino Linotype", "Palatino LT STD", Georgia, serif;
    margin: 0;
    /*text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);*/
    font-size: 6em;
}

.intro-message img.logo {
    width: 50%;
}

.intro-divider {
    width: 400px;
    border-top: 1px solid #f8f8f8;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.intro-message > h3 {
    /*text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);*/
}

.intro-social-buttons i {
    font-size: 80%;
}

@media (max-width: 767px) {
    .intro-message {
        font-family: "Book Antiqua", Palatino, "Palatino Linotype", "Palatino LT STD", Georgia, serif;
        padding-bottom: 15%;
    }

    .intro-message > h1 {
        font-size: 3em;
    }

    .intro-message img.logo {
        width: 75%;
    }

    ul.intro-social-buttons > li {
        display: block;
        margin-bottom: 20px;
        padding: 0;
    }

    ul.intro-social-buttons > li:last-child {
        margin-bottom: 0;
    }

    .intro-divider {
        width: 100%;
    }
}

.network-name {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 2px;
}

.content-section-a {
    padding: 50px 0;
    background-color: #f8f8f8;
}

.content-section-b {
    padding: 50px 0;
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}

.section-heading {
    margin-bottom: 30px;
}

.section-heading-spacer {
    float: left;
    width: 200px;
    border-top: 3px solid #e7e7e7;
}

.banner {
    padding: 100px 0;
    color: #f8f8f8;
    /*background: url(../img/banner-bg.jpg) no-repeat center center;*/
    background-size: cover;
}

.banner h2 {
    margin: 0;
    /*text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);*/
    font-size: 3em;
}

.banner ul {
    margin-bottom: 0;
}

.banner-social-buttons {
    float: right;
    margin-top: 0;
}

@media (max-width: 1199px) {
    ul.banner-social-buttons {
        float: left;
        margin-top: 15px;
    }
}

@media (max-width: 767px) {
    .banner h2 {
        margin: 0;
        /*text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);*/
        font-size: 3em;
    }

    ul.banner-social-buttons > li {
        display: block;
        margin-bottom: 20px;
        padding: 0;
    }

    ul.banner-social-buttons > li:last-child {
        margin-bottom: 0;
    }
}

footer {
    padding: 50px 0;
    background-color: #f8f8f8;
}

p.copyright {
    margin: 15px 0 0;
}

.btn-info {
    color: #fff;
    background-color: #0098e6;
    border-color: #0098e6;
    font-weight: bold;
}

.btn-info:hover {
    color: #0098e6;
    background-color: #fff;
    border-color: #0098e6;
    font-weight: bold;
}

a, a:hover, a:visited {
    color: #0098e6;
}

.learn-more {
    color: #212529;
}

.headshot-cell {
    padding: 0 20px 0 20px;
}

div.affiliation-logos {
    text-align: center;
}

div.affiliation-logos img {
    width: 6em;
}